import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useAuth } from "../contexts/AuthContext";
import IlmoittautumisenPoisto from "./IlmoittautumisenPoisto";
import IlmoittautumistenLisays from "./IlmoittautumistenLisays";
import { ILMOITTAUTUMISET_JA_KONEET_QUERY } from "../graphql/queries";
import { IlmoittautuminenEnum } from "../enums/IlmoittautuminenEnum";
import { Ilmoittautuminen } from "../types";

export default function Ilmoittautumiset() {
  const { user } = useAuth();
  const [haeIlmoittautumisetJaKoneet, { data, refetch }] = useLazyQuery(
    ILMOITTAUTUMISET_JA_KONEET_QUERY
  );
  const [piilotettu, asetaPiilotettu] = useState(true);
  const [lopputulos, asetaLopputulos] = useState(0);

  useEffect(() => {
    user && haeIlmoittautumisetJaKoneet({ variables: { id: user.id } });
  }, [user, haeIlmoittautumisetJaKoneet]);

  const kasitteleLopputulos = (lopputulos: IlmoittautuminenEnum) => {
    const onnistuneetOperaatiot = [
      IlmoittautuminenEnum.LisaaminenOnnistui,
      IlmoittautuminenEnum.PoistaminenOnnistui,
    ];
    onnistuneetOperaatiot.includes(lopputulos) && refetch();
    lopputulos === IlmoittautuminenEnum.LisaaminenOnnistui &&
      asetaPiilotettu((vanha) => !vanha);
    asetaLopputulos(lopputulos);
  };

  const palautaIlmoitus = () => {
    switch (lopputulos) {
      case IlmoittautuminenEnum.LisaaminenOnnistui:
      case IlmoittautuminenEnum.PoistaminenOnnistui:
        return (
          <Alert severity="success" sx={{ marginBottom: "1rem" }}>
            Ilmoittautumisen {palautaOperaatio()} onnistui
          </Alert>
        );
      case IlmoittautuminenEnum.LisaaminenEpaonnistui:
      case IlmoittautuminenEnum.PoistaminenEpaonnistui:
        return (
          <Alert severity="error" sx={{ marginBottom: "1rem" }}>
            Ilmoittautumisen {palautaOperaatio()} epäonnistui
          </Alert>
        );
    }
  };

  const palautaOperaatio = () => {
    switch (lopputulos) {
      case IlmoittautuminenEnum.LisaaminenOnnistui:
      case IlmoittautuminenEnum.LisaaminenEpaonnistui:
        return "lisääminen";
      case IlmoittautuminenEnum.PoistaminenOnnistui:
      case IlmoittautuminenEnum.PoistaminenEpaonnistui:
        return "poistaminen";
    }
  };

  if (!data || !user) return null;

  return (
    <>
      <Typography component="h1" variant="h5">
        Ilmoittautumiset
      </Typography>
      {lopputulos !== 0 && palautaIlmoitus()}
      {data.ilmoittautuminens.length !== 0 && (
        <>
          <TableContainer component={Paper} sx={{ margin: "1rem 0" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Kone</TableCell>
                  <TableCell>Osakilpailu</TableCell>
                  <TableCell>Luokka</TableCell>
                  <TableCell>Kuljettaja</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.ilmoittautuminens.map(
                  (ilmoittautuminen: Ilmoittautuminen) => (
                    <TableRow
                      key={ilmoittautuminen.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{ilmoittautuminen.kone?.nimi}</TableCell>
                      <TableCell>{ilmoittautuminen.osakilpailu.nimi}</TableCell>
                      <TableCell>{ilmoittautuminen.luokka.nimi}</TableCell>
                      <TableCell>{ilmoittautuminen.user.name}</TableCell>
                      <TableCell align="right">
                        <IlmoittautumisenPoisto
                          id={ilmoittautuminen.id}
                          callback={kasitteleLopputulos}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {data.kones.length === 0 ? (
        <>
          <p>
            Aloita lisäämällä kone Koneet-osiossa ja palaa sitten lisäämään
            ilmoittautuminen.
          </p>
          <Link to="/koneet">Siirry Koneet-osioon &rarr;</Link>
        </>
      ) : (
        <>
          {data.ilmoittautuminens.length === 0 && (
            <p>Sinulla ei ole vielä ilmoittautumisia.</p>
          )}
          <Button
            variant="contained"
            onClick={() => asetaPiilotettu((vanha) => !vanha)}
            sx={{ mt: 3, mb: 2 }}
          >
            Lisää ilmoittautuminen
          </Button>
        </>
      )}
      {!piilotettu && (
        <IlmoittautumistenLisays user={user} callback={kasitteleLopputulos} />
      )}
    </>
  );
}
