import { gql } from "@apollo/client";
import { TypedDocumentNode } from "@apollo/client";

export const ALASEURAT_QUERY: TypedDocumentNode = gql`
  query Alaseurat {
    alaseuras(orderBy: { nimi: asc }) {
      id
      nimi
    }
  }
`;

export const ILMOITTAUTUMISET_JA_KONEET_QUERY: TypedDocumentNode = gql`
  query IlmoittautumisetJaKoneet($id: ID!) {
    ilmoittautuminens(where: { user: { id: { equals: $id } } }) {
      id
      kone {
        nimi
      }
      osakilpailu {
        nimi
      }
      luokka {
        nimi
      }
      user {
        name
      }
    }
    kones(where: { user: { id: { equals: $id } } }) {
      id
    }
  }
`;

export const KAYTTAJA_QUERY: TypedDocumentNode = gql`
  query User($id: ID!) {
    users(where: { id: { equals: $id } }) {
      id
      name
      email
      puhelinnumero
      katuosoite
      postinumero
      postitoimipaikka
      tilinumero
      tiimi
      alaseura {
        id
        label: nimi
      }
    }
  }
`;

export const KAYTTAJAT_QUERY: TypedDocumentNode = gql`
  query Users {
    users(orderBy: { name: asc }) {
      id
      name
    }
  }
`;

export const KIRJAUDU_SISAAN_MUTATION: TypedDocumentNode = gql`
  mutation ($email: String!, $password: String!) {
    authenticateUserWithPassword(email: $email, password: $password) {
      ... on UserAuthenticationWithPasswordSuccess {
        item {
          id
          email
        }
      }
      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
`;

export const KIRJAUDU_ULOS_MUTATION: TypedDocumentNode = gql`
  mutation {
    endSession
  }
`;

export const KIRJAUTUNUT_KAYTTAJA_QUERY: TypedDocumentNode = gql`
  query AuthenticatedItem {
    authenticatedItem {
      ... on User {
        email
        id
        name
        kuva {
          url
        }
      }
    }
  }
`;

export const KONEEN_LUOKAT_QUERY: TypedDocumentNode = gql`
  query KoneenLuokat($luokat: [ID!], $kuluvaVuosi: DateTime!) {
    luokkas(where: { id: { in: $luokat } }, orderBy: { nimi: asc }) {
      id
      nimi
      osakilpailut(where: { ajankohta: { gte: $kuluvaVuosi } }) {
        id
        nimi
        ajankohta
        viimeinenIlmoittautumispaiva
      }
    }
  }
`;

export const KONEET_QUERY: TypedDocumentNode = gql`
  query Koneet($id: ID!) {
    kones(where: { user: { id: { equals: $id } } }, orderBy: { nimi: asc }) {
      id
      nimi
      merkki
      malli
      rekisterinumero
      luokat {
        id
      }
      moottoritiedot
      maksimiRpm
      voimansiirtotiedot
      lisatiedot
      yhteistyokumppanit {
        id
      }
      kuva {
        url
      }
      user {
        email
        id
        name
      }
    }
  }
`;

export const LAHETA_LINKKI_SALASANAN_PAIVITTAMISEKSI_MUTATION: TypedDocumentNode = gql`
  mutation ($email: String!) {
    sendUserPasswordResetLink(email: $email)
  }
`;

export const LISAA_ILMOITTAUTUMISET_MUTATION: TypedDocumentNode = gql`
  mutation ($data: [IlmoittautuminenCreateInput!]!) {
    createIlmoittautuminens(data: $data) {
      id
      label: id
      __typename
    }
  }
`;

export const LISAA_KONE_MUTATION: TypedDocumentNode = gql`
  mutation ($data: KoneCreateInput!) {
    item: createKone(data: $data) {
      id
      label: nimi
      __typename
    }
  }
`;

export const LISAA_KOOSTE_MUTATION: TypedDocumentNode = gql`
  mutation ($data: KoosteCreateInput!) {
    item: createKooste(data: $data) {
      id
      label: id
      __typename
    }
  }
`;

export const LUOKAT_QUERY: TypedDocumentNode = gql`
  query Luokat {
    luokkas(orderBy: { nimi: asc }) {
      id
      nimi
    }
  }
`;

export const MUOKKAA_KONETTA_MUTATION: TypedDocumentNode = gql`
  mutation ($data: KoneUpdateInput!, $id: ID!) {
    item: updateKone(where: { id: $id }, data: $data) {
      id
      nimi
      merkki
      malli
      rekisterinumero
      luokat {
        id
        label: nimi
        __typename
      }
      moottoritiedot
      maksimiRpm
      voimansiirtotiedot
      lisatiedot
      yhteistyokumppanit {
        id
        label: nimi
        __typename
      }
      kuva {
        url
      }
      ilmoittautumiset {
        id
        label: id
        __typename
      }
      user {
        id
        label: name
        __typename
      }
      __typename
    }
  }
`;

export const MUOKKAA_KAYTTAJAA_MUTATION: TypedDocumentNode = gql`
  mutation ($data: UserUpdateInput!, $id: ID!) {
    item: updateUser(where: { id: $id }, data: $data) {
      id
      name
      email
      password {
        isSet
        __typename
      }
      isAdmin
      puhelinnumero
      katuosoite
      postinumero
      postitoimipaikka
      tilinumero
      tiimi
      alaseura {
        id
        label: nimi
        __typename
      }
      koneet {
        id
        label: nimi
        __typename
      }
      lisenssi {
        id
        label: id
        __typename
      }
      roolit {
        id
        label: id
        __typename
      }
      __typename
    }
  }
`;

export const PAIVITA_SALASANA_MUTATION: TypedDocumentNode = gql`
  mutation ($email: String!, $token: String!, $password: String!) {
    redeemUserPasswordResetToken(
      email: $email
      token: $token
      password: $password
    ) {
      code
    }
  }
`;

export const POISTA_ILMOITTAUTUMINEN_MUTATION: TypedDocumentNode = gql`
  mutation ($where: [IlmoittautuminenWhereUniqueInput!]!) {
    deleteIlmoittautuminens(where: $where) {
      id
      __typename
    }
  }
`;

export const POISTA_KONE_MUTATION: TypedDocumentNode = gql`
  mutation ($where: [KoneWhereUniqueInput!]!) {
    deleteKones(where: $where) {
      id
      __typename
    }
  }
`;

export const REKISTEROIDY_MUTATION: TypedDocumentNode = gql`
  mutation ($data: UserCreateInput!) {
    item: createUser(data: $data) {
      id
      label: name
      __typename
    }
  }
`;

export const KONEEN_YHTEISTYOKUMPPANIT_QUERY: TypedDocumentNode = gql`
  query KoneenYhteistyokumppanit($id: ID!) {
    yhteistyokumppanis(where: { koneet: { some: { id: { equals: $id } } } }) {
      id
      nimi
      logo {
        id
        extension
        url
      }
      kotisivut
      lisatiedot
    }
  }
`;

export const LISAA_YHTEISTYOKUMPPANI_MUTATION: TypedDocumentNode = gql`
  mutation ($data: YhteistyokumppaniCreateInput!) {
    item: createYhteistyokumppani(data: $data) {
      id
      label: nimi
      __typename
    }
  }
`;

export const MUOKKAA_YHTEISTYOKUMPPANIA_MUTATION: TypedDocumentNode = gql`
  mutation ($data: YhteistyokumppaniUpdateInput!, $id: ID!) {
    item: updateYhteistyokumppani(where: { id: $id }, data: $data) {
      id
      nimi
      logo {
        url
        id
        extension
        width
        height
        filesize
        __typename
      }
      kotisivut
      lisatiedot
      koneet {
        id
        label: nimi
        __typename
      }
      __typename
    }
  }
`;
