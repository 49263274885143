import React from "react";
import ReactDOM from "react-dom/client";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import App from "./App";

const link = createHttpLink({
  credentials: "include",
  uri: process.env.REACT_APP_API_URL,
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Kone: {
        fields: {
          luokat: {
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  }),
  link,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);
