import Container from "@mui/material/Container";
import Header from "./Header";
import Copyright from "./Copyright";
import styles from "../styles/Home.module.css";

const Sivu: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <Header />
    <Container component="main" maxWidth="sm" className={styles.main}>
      {children}
    </Container>
    <footer>
      <Copyright />
    </footer>
  </>
);

export default Sivu;
