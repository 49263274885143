import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useAuth } from "../contexts/AuthContext";
import KoneenLisaysTaiMuokkaus from "./KoneenLisaysTaiMuokkaus";
import KoneenPoisto from "./KoneenPoisto";
import {
  ILMOITTAUTUMISET_JA_KONEET_QUERY,
  KONEET_QUERY,
} from "../graphql/queries";
import { KoneEnum } from "../enums/KoneEnum";
import { Kone } from "../types";

const onnistuneetOperaatiot = [
  KoneEnum.LisaaminenOnnistui,
  KoneEnum.PoistaminenOnnistui,
  KoneEnum.MuokkaaminenOnnistui,
];

export default function Koneet() {
  const { user } = useAuth();
  const [haeKoneet, { data, refetch }] = useLazyQuery(KONEET_QUERY);
  const [haeIlmoittautumisetJaKoneet] = useLazyQuery(
    ILMOITTAUTUMISET_JA_KONEET_QUERY,
    { fetchPolicy: "network-only" }
  );
  const [lopputulos, asetaLopputulos] = useState(0);

  useEffect(() => {
    user && haeKoneet({ variables: { id: user.id } });
  }, [user, haeKoneet]);

  const kasitteleLopputulos = (lopputulos: KoneEnum) => {
    if (onnistuneetOperaatiot.includes(lopputulos)) {
      refetch();
      user && haeIlmoittautumisetJaKoneet({ variables: { id: user.id } });
    }
    asetaLopputulos(lopputulos);
  };

  const palautaIlmoitus = () => {
    switch (lopputulos) {
      case KoneEnum.LisaaminenOnnistui:
      case KoneEnum.PoistaminenOnnistui:
      case KoneEnum.MuokkaaminenOnnistui:
        return (
          <Alert severity="success" sx={{ marginBottom: "1rem" }}>
            Koneen {palautaOperaatio()} onnistui
            {lopputulos === KoneEnum.LisaaminenOnnistui &&
              ". Voit nyt lisätä koneelle ilmoittautumisen Ilmoittautumiset-osiossa."}
          </Alert>
        );
      case KoneEnum.LisaaminenEpaonnistui:
      case KoneEnum.PoistaminenEpaonnistui:
      case KoneEnum.MuokkaaminenEpaonnistui:
        return (
          <Alert severity="error" sx={{ marginBottom: "1rem" }}>
            Koneen {palautaOperaatio()} epäonnistui
          </Alert>
        );
    }
  };

  const palautaOperaatio = () => {
    switch (lopputulos) {
      case KoneEnum.LisaaminenOnnistui:
      case KoneEnum.LisaaminenEpaonnistui:
        return "lisääminen";
      case KoneEnum.PoistaminenOnnistui:
      case KoneEnum.PoistaminenEpaonnistui:
        return "poistaminen";
      case KoneEnum.MuokkaaminenOnnistui:
      case KoneEnum.MuokkaaminenEpaonnistui:
        return "muokkaaminen";
    }
  };

  if (!data || !user) return null;

  return (
    <>
      <Typography component="h1" variant="h5">
        Koneet
      </Typography>
      {lopputulos !== 0 && palautaIlmoitus()}
      {data.kones.length === 0 ? (
        <p>Sinulla ei ole vielä lisättyjä koneita.</p>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ margin: "1rem 0" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nimi</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.kones.map((kone: Kone) => (
                  <TableRow
                    key={kone.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{kone.nimi}</TableCell>
                    <TableCell align="right" sx={{ width: "20%" }}>
                      {user?.id === kone.user.id && (
                        <KoneenLisaysTaiMuokkaus
                          variant="outlined"
                          kone={kone}
                          user={user}
                          value="Muokkaa"
                          title="Muokkaa konetta"
                          callback={kasitteleLopputulos}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right" sx={{ width: "20%" }}>
                      {user?.id === kone.user.id && (
                        <KoneenPoisto
                          id={kone.id}
                          user={user}
                          callback={kasitteleLopputulos}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <KoneenLisaysTaiMuokkaus
        variant="contained"
        user={user}
        value="Lisää kone"
        title="Lisää kone"
        callback={kasitteleLopputulos}
        sx={{ mt: 3, mb: 2 }}
      />
    </>
  );
}
