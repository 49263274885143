import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Menu from "./Menu";

const StyledLink = styled(Link)(() => ({
  backgroundImage: "linear-gradient(to right, #0ea5e9, #6366f1)",
  backgroundClip: "text",
  color: "transparent",
  fontSize: "1.25rem",
  fontWeight: 700,
  lineHeight: "1.75rem",
  textDecoration: "none",
  transition: "color 0.3s ease",
  verticalAlign: "middle",
}));

export default function Header() {
  return (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography variant="h3" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <StyledLink to="/">FTPA</StyledLink>
          </Typography>
          <Menu />
        </Toolbar>
      </AppBar>
    </>
  );
}
