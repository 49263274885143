import Typography from "@mui/material/Typography";
import { KonePick, Luokka, Osakilpailu, User } from "../types";

interface IlmoittumisenYhteenvetoProps {
  valittuKone: KonePick;
  valitutLuokat: Luokka[];
  valitutOsakilpailut: Osakilpailu[];
  valittuKuljettaja: User;
}

const IlmoittautumisenYhteenveto: React.FC<IlmoittumisenYhteenvetoProps> = ({
  valittuKone,
  valitutLuokat,
  valitutOsakilpailut,
  valittuKuljettaja,
}) => {
  return (
    <>
      <Typography variant="h6">Yhteenveto</Typography>
      <ul>
        <li>
          <Typography display="inline" sx={{ fontWeight: "bold" }}>
            Kone:
          </Typography>
          <Typography display="inline" sx={{ marginLeft: "0.5rem" }}>
            {valittuKone.nimi}
          </Typography>
        </li>
        <li>
          <Typography display="inline" sx={{ fontWeight: "bold" }}>
            Luokat:
          </Typography>
          <Typography display="inline" sx={{ marginLeft: "0.5rem" }}>
            {valitutLuokat.map((luokka) => luokka.nimi).join(", ")}
          </Typography>
        </li>
        <li>
          <Typography display="inline" sx={{ fontWeight: "bold" }}>
            Osakilpailut:
          </Typography>
          <Typography display="inline" sx={{ marginLeft: "0.5rem" }}>
            {valitutOsakilpailut
              .map(
                (osakilpailu) =>
                  new Date(osakilpailu.ajankohta).toLocaleDateString("fi-FI") +
                  " " +
                  osakilpailu.nimi
              )
              .join(", ")}
          </Typography>
        </li>
        <li>
          <Typography display="inline" sx={{ fontWeight: "bold" }}>
            Kuljettaja:
          </Typography>
          <Typography display="inline" sx={{ marginLeft: "0.5rem" }}>
            {valittuKuljettaja.name}
          </Typography>
        </li>
      </ul>
    </>
  );
};

export default IlmoittautumisenYhteenveto;
