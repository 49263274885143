import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { print } from "graphql";
import { useFormik, FormikProvider, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  KONEEN_YHTEISTYOKUMPPANIT_QUERY,
  LISAA_YHTEISTYOKUMPPANI_MUTATION,
  MUOKKAA_YHTEISTYOKUMPPANIA_MUTATION,
} from "../graphql/queries";
import {
  Kone,
  Yhteistyokumppani,
  YhteistyokumppaninLisaysTaiMuokkausOperaatio,
} from "../types";

interface YhteistyokumppanitProps {
  kone?: Kone;
  callback: React.Dispatch<
    React.SetStateAction<(koneId: string) => Promise<void>>
  >;
}

const validationSchema = Yup.object().shape({
  yhteistyokumppanit: Yup.array().of(
    Yup.object().shape({
      nimi: Yup.string().required("Tämä on pakollinen kenttä"),
      kotisivut: Yup.string(),
      lisatiedot: Yup.string(),
    })
  ),
});

const uusiYhteistyokumppani: Omit<Yhteistyokumppani, "id"> = {
  nimi: "",
  logo: {
    file: null,
    url: "placeholder.svg",
  },
  kotisivut: "",
  lisatiedot: "",
};

const Yhteistyokumppanit: React.FC<YhteistyokumppanitProps> = ({
  kone,
  callback,
}) => {
  const { data, refetch } = useQuery(KONEEN_YHTEISTYOKUMPPANIT_QUERY, {
    variables: { id: kone?.id },
    skip: !kone,
    onCompleted(data) {
      formik.setValues({ yhteistyokumppanit: data.yhteistyokumppanis });
    },
  });

  const initialValues: Yhteistyokumppani[] = [];

  const formik = useFormik({
    initialValues: { yhteistyokumppanit: initialValues },
    validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    const kasitteleYhteistyokumppani = async (
      yhteistyokumppani: Yhteistyokumppani,
      koneId: string,
      poista?: boolean
    ): Promise<void> => {
      const operations = muodostaOperaatio(yhteistyokumppani, koneId, poista);
      const body = muodostaBody(yhteistyokumppani, operations);
      await lahetaPyynto(body);
      refetch({ id: koneId });
    };

    const lisaaYhteistyokumppaniTaiMuokkaaYhteistyokumppania = async (
      koneId: string
    ): Promise<void> => {
      const poistettavat = data?.yhteistyokumppanis
        .map((yhteistyokumppani: Yhteistyokumppani) => yhteistyokumppani.id)
        .filter(
          (id: string) =>
            !formik.values.yhteistyokumppanit.some((y) => y.id === id)
        );

      if (data?.yhteistyokumppanis) {
        await Promise.all(
          data.yhteistyokumppanis
            .filter((yhteistyokumppani: Yhteistyokumppani) =>
              poistettavat.includes(yhteistyokumppani.id)
            )
            .map((yhteistyokumppani: Yhteistyokumppani) =>
              kasitteleYhteistyokumppani(yhteistyokumppani, koneId, true)
            )
        );
      }

      await Promise.all(
        formik.values.yhteistyokumppanit.map((yhteistyokumppani) =>
          kasitteleYhteistyokumppani(yhteistyokumppani, koneId)
        )
      );
    };

    callback(() => lisaaYhteistyokumppaniTaiMuokkaaYhteistyokumppania);
  }, [refetch, data, formik.values, callback]);

  const muodostaOperaatio = (
    yhteistyokumppani: Yhteistyokumppani,
    koneId: string,
    poista?: boolean
  ): YhteistyokumppaninLisaysTaiMuokkausOperaatio => ({
    query: print(
      yhteistyokumppani.id
        ? MUOKKAA_YHTEISTYOKUMPPANIA_MUTATION
        : LISAA_YHTEISTYOKUMPPANI_MUTATION
    ),
    variables: {
      ...(yhteistyokumppani.id ? { id: yhteistyokumppani.id } : {}),
      data: {
        nimi: yhteistyokumppani.nimi,
        kotisivut: yhteistyokumppani.kotisivut,
        lisatiedot: yhteistyokumppani.lisatiedot,
        ...(yhteistyokumppani.logo?.file
          ? { logo: { upload: null } }
          : { logo: null }),
        koneet: {
          connect: !poista ? [{ id: koneId }] : undefined,
          disconnect: poista ? [{ id: koneId }] : undefined,
        },
      },
    },
  });

  const muodostaBody = (
    values: Yhteistyokumppani,
    operations: YhteistyokumppaninLisaysTaiMuokkausOperaatio
  ): FormData | string => {
    if (!values.logo?.file) return JSON.stringify(operations);
    const formData = new FormData();
    formData.append("operations", JSON.stringify(operations));
    formData.append(
      "map",
      JSON.stringify({ 0: ["variables.data.logo.upload"] })
    );
    formData.append("0", values.logo.file);
    return formData;
  };

  const lahetaPyynto = async (body: FormData | string): Promise<void> => {
    try {
      await fetch(process.env.REACT_APP_API_URL!, {
        method: "POST",
        headers:
          body instanceof FormData
            ? {}
            : {
                "Content-Type": "application/json",
              },
        credentials: "include",
        body,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="yhteistyokumppanit"
        render={(arrayHelpers) => (
          <>
            {formik.values.yhteistyokumppanit.map((_, index) => (
              <div key={index}>
                <TextField
                  name={`yhteistyokumppanit[${index}].nimi`}
                  label="Nimi"
                  value={formik.values.yhteistyokumppanit[index].nimi}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.yhteistyokumppanit?.[index]?.nimi &&
                    !!getIn(formik.errors, `yhteistyokumppanit[${index}].nimi`)
                  }
                  helperText={
                    formik.touched.yhteistyokumppanit?.[index]?.nimi &&
                    getIn(formik.errors, `yhteistyokumppanit[${index}].nimi`)
                  }
                  fullWidth
                  margin="normal"
                  required
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box
                    component="img"
                    src={
                      formik.values.yhteistyokumppanit[index].logo?.url ||
                      "placeholder.svg"
                    }
                    alt="Yhteistyökumppani"
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: "4px",
                      maxWidth: "100px",
                    }}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    sx={{ margin: "0 0.5rem" }}
                  >
                    {formik.values.yhteistyokumppanit[index].logo &&
                    formik.values.yhteistyokumppanit[index].logo?.url !==
                      "placeholder.svg"
                      ? "Vaihda"
                      : "Valitse kuva"}
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(event) => {
                        if (event.target.files && event.target.files[0]) {
                          formik.setFieldValue(
                            `yhteistyokumppanit[${index}].logo.file`,
                            event.target.files[0]
                          );
                          formik.setFieldValue(
                            `yhteistyokumppanit[${index}].logo.url`,
                            URL.createObjectURL(event.target.files[0])
                          );
                        }
                      }}
                    />
                  </Button>
                  {formik.values.yhteistyokumppanit[index].logo &&
                    formik.values.yhteistyokumppanit[index].logo?.url !==
                      "placeholder.svg" && (
                      <Button
                        variant="contained"
                        onClick={() =>
                          formik.setFieldValue(
                            `yhteistyokumppanit[${index}].logo`,
                            undefined
                          )
                        }
                      >
                        Poista
                      </Button>
                    )}
                </Box>
                <TextField
                  name={`yhteistyokumppanit[${index}].kotisivut`}
                  label="Kotisivut"
                  value={formik.values.yhteistyokumppanit[index].kotisivut}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name={`yhteistyokumppanit[${index}].lisatiedot`}
                  label="Lisätiedot"
                  value={formik.values.yhteistyokumppanit[index].lisatiedot}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  margin="normal"
                />
                <Button
                  type="button"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  -
                </Button>
                <Button
                  type="button"
                  onClick={() => arrayHelpers.push(uusiYhteistyokumppani)}
                >
                  +
                </Button>
              </div>
            ))}
            {formik.values.yhteistyokumppanit.length === 0 && (
              <Button
                type="button"
                onClick={() => arrayHelpers.push(uusiYhteistyokumppani)}
              >
                Lisää yhteistyökumppani
              </Button>
            )}
          </>
        )}
      />
    </FormikProvider>
  );
};

export default Yhteistyokumppanit;
