import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

export default function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ paddingBottom: 1 }}
    >
      © {new Date().getFullYear()}{" "}
      <Link to="/">Finnish Tractor Pulling Association</Link>
    </Typography>
  );
}
