import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Drawer, List, ListItem, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../contexts/AuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Uloskirjautuminen from "./Uloskirjautuminen";

export default function Menu() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      )
        return;
      setOpen(open);
    },
    []
  );

  const loggedInListItems = (
    <>
      <ListItem component={Link} to="/ilmoittautumiset">
        Ilmoittautumiset
      </ListItem>
      <ListItem component={Link} to="/koneet">
        Koneet
      </ListItem>
      <ListItem component={Link} to="/kayttajatili">
        Käyttäjätili
      </ListItem>
      <ListItem>
        <Uloskirjautuminen />
      </ListItem>
    </>
  );

  const loggedOutListItems = (
    <>
      <ListItem>
        <Button component={Link} to="/kirjaudu" variant="outlined">
          Kirjaudu sisään
        </Button>
      </ListItem>
      <ListItem>
        <Button component={Link} to="/rekisteroidy" variant="outlined">
          Rekisteröidy
        </Button>
      </ListItem>
    </>
  );

  if (isSmallScreen) {
    return (
      <div>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
          <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>{user ? loggedInListItems : loggedOutListItems}</List>
          </div>
        </Drawer>
      </div>
    );
  }

  return (
    <nav>
      <List sx={{ display: "flex", whiteSpace: "nowrap" }}>
        {user ? loggedInListItems : loggedOutListItems}
      </List>
    </nav>
  );
}
