import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAuth } from "../contexts/AuthContext";
import styles from "../styles/Home.module.css";

export default function Koti() {
  const { user } = useAuth();

  return (
    <>
      {!user ? (
        <>
          <Typography component="h1" variant="h5">
            Tervetuloa FTPA:n ilmoittautumisjärjestelmään
          </Typography>
          <p>
            <Link to="/kirjaudu">
              Aloita kirjautumalla sisään tai rekisteröitymällä &rarr;
            </Link>
          </p>
        </>
      ) : (
        <Box className={styles.grid}>
          <Typography component="h1" variant="h6">
            <Link to="/ilmoittautumiset" className={styles.card}>
              Ilmoittautumiset &rarr;
            </Link>
          </Typography>
          <Typography component="h1" variant="h6">
            <Link to="/koneet" className={styles.card}>
              Koneet &rarr;
            </Link>
          </Typography>
          <Typography component="h1" variant="h6">
            <Link to="/kayttajatili" className={styles.card}>
              Käyttäjätili &rarr;
            </Link>
          </Typography>
        </Box>
      )}
    </>
  );
}
