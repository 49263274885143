import { useState } from "react";
import { useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IlmoittautuminenEnum } from "../enums/IlmoittautuminenEnum";
import { POISTA_ILMOITTAUTUMINEN_MUTATION } from "../graphql/queries";

interface IlmoittautumisenPoistoProps {
  id: string;
  callback: (lopputulos: IlmoittautuminenEnum) => void;
}

const IlmoittautumisenPoisto: React.FC<IlmoittautumisenPoistoProps> = ({
  id,
  callback,
}) => {
  const [auki, asetaAuki] = useState(false);

  const [IlmoittautumisenPoisto] = useMutation(
    POISTA_ILMOITTAUTUMINEN_MUTATION
  );

  const kasitteleAvaa = () => {
    asetaAuki(true);
  };

  const kasitteleSulje = (lopputulos = 0) => {
    asetaAuki(false);
    callback(lopputulos);
  };

  const kasitteleIlmoittautumisenPoisto = async () => {
    try {
      await IlmoittautumisenPoisto({
        variables: {
          where: [
            {
              id: id,
            },
          ],
        },
      });
      kasitteleSulje(IlmoittautuminenEnum.PoistaminenOnnistui);
    } catch {
      kasitteleSulje(IlmoittautuminenEnum.PoistaminenEpaonnistui);
    }
  };

  return (
    <div>
      <Button variant="outlined" onClick={kasitteleAvaa}>
        Poista
      </Button>
      <Dialog open={auki} keepMounted onClose={() => kasitteleSulje()}>
        <DialogTitle>Poista ilmoittautuminen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Haluatko varmasti poistaa tämän ilmoittautumisen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => kasitteleSulje()}>Peruuta</Button>
          <Button onClick={kasitteleIlmoittautumisenPoisto}>Poista</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IlmoittautumisenPoisto;
