import { useState, useReducer, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { print } from "graphql";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SxProps } from "@mui/system";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme, useTheme } from "@mui/material/styles";
import Yhteistyokumppanit from "./Yhteistyokumppanit";
import {
  LISAA_KONE_MUTATION,
  LUOKAT_QUERY,
  MUOKKAA_KONETTA_MUTATION,
} from "../graphql/queries";
import { KoneEnum } from "../enums/KoneEnum";
import { KuvaEnum } from "../enums/KuvaEnum";
import {
  Action,
  Kone,
  KoneenLisaysTaiMuokkausOperaatio,
  KoneLomake,
  Luokka,
  State,
  User,
} from "../types";

interface KoneenLisaysTaiMuokkausProps {
  variant?: "text" | "outlined" | "contained" | undefined;
  kone?: Kone | undefined;
  user: User;
  value: string;
  title: string;
  callback: (lopputulos: KoneEnum) => void;
  sx?: SxProps<Theme> | undefined;
}

const validationSchema = Yup.object().shape({
  nimi: Yup.string().required("Tämä on pakollinen kenttä"),
  merkki: Yup.string(),
  malli: Yup.string(),
  rekisterinumero: Yup.string(),
  maksimiRpm: Yup.number(),
  moottoritiedot: Yup.string(),
  voimansiirtotiedot: Yup.string(),
  luokat: Yup.array().of(Yup.string()),
  yhteistyokumppanit: Yup.array().of(Yup.string()),
  lisatiedot: Yup.string(),
});

const KoneenLisaysTaiMuokkaus: React.FC<KoneenLisaysTaiMuokkausProps> = ({
  variant,
  kone,
  user,
  value,
  title,
  callback,
  sx,
}) => {
  const initialState: State = {
    status: kone?.kuva?.url ? KuvaEnum.On : KuvaEnum.Ei,
    url: kone?.kuva?.url || "placeholder.svg",
  };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "lisaa":
        return {
          status: KuvaEnum.Lisatty,
          url: action.payload?.url || state.url,
        };
      case "poista":
        return { status: KuvaEnum.Poistettu, url: "placeholder.svg" };
      case "nollaa":
        return initialState;
      default:
        throw new Error();
    }
  };

  const { data } = useQuery(LUOKAT_QUERY);
  const [auki, asetaAuki] = useState(false);
  const [yhteistyokumppanitCallback, asetaYhteistyokumppanitCallback] =
    useState<(koneId: string) => Promise<void>>(() => async (_: string) => {});
  const [state, dispatch] = useReducer(reducer, initialState);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch({ type: "nollaa" });
  }, [kone?.kuva?.url]);

  const formik = useFormik<KoneLomake>({
    initialValues: {
      nimi: kone?.nimi || "",
      merkki: kone?.merkki || "",
      malli: kone?.malli || "",
      rekisterinumero: kone?.rekisterinumero || "",
      maksimiRpm: kone?.maksimiRpm || undefined,
      moottoritiedot: kone?.moottoritiedot || "",
      voimansiirtotiedot: kone?.voimansiirtotiedot || "",
      luokat: kone?.luokat ? kone.luokat.map((luokka) => luokka.id) : [],
      yhteistyokumppanit: kone?.yhteistyokumppanit
        ? kone.yhteistyokumppanit.map(
            (yhteistyokumppani) => yhteistyokumppani.id
          )
        : [],
      lisatiedot: kone?.lisatiedot || "",
      kuva: undefined,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const koneId = await lisaaKoneTaiMuokkaaKonetta(values);
        await yhteistyokumppanitCallback(koneId);
        kasitteleSulje(
          kone ? KoneEnum.MuokkaaminenOnnistui : KoneEnum.LisaaminenOnnistui
        );
      } catch {
        kasitteleSulje(
          kone
            ? KoneEnum.MuokkaaminenEpaonnistui
            : KoneEnum.LisaaminenEpaonnistui
        );
      }
    },
  });

  const lisaaKoneTaiMuokkaaKonetta = async (
    values: KoneLomake
  ): Promise<string> => {
    const operations = muodostaOperaatio(values);
    const body = muodostaBody(values, operations);
    return await lahetaPyynto(body);
  };

  const muodostaOperaatio = (
    values: KoneLomake
  ): KoneenLisaysTaiMuokkausOperaatio => ({
    query: print(kone ? MUOKKAA_KONETTA_MUTATION : LISAA_KONE_MUTATION),
    variables: {
      ...(kone ? { id: kone.id } : {}),
      data: {
        ...values,
        kuva: values.kuva
          ? {
              upload: null,
            }
          : null,
        luokat: {
          connect: values.luokat.map((luokka) => ({ id: luokka })),
          disconnect: kone
            ? kone.luokat
                .filter((luokka) => !values.luokat.includes(luokka.id))
                .map((luokka) => ({ id: luokka.id }))
            : undefined,
        },
        yhteistyokumppanit: {
          connect: [],
          disconnect: undefined,
        },
        user: {
          connect: {
            id: kone?.user.id || user.id,
          },
        },
      },
    },
  });

  const muodostaBody = (
    values: KoneLomake,
    operations: KoneenLisaysTaiMuokkausOperaatio
  ): FormData | string => {
    if (!values.kuva) return JSON.stringify(operations);
    const formData = new FormData();
    formData.append("operations", JSON.stringify(operations));
    formData.append(
      "map",
      JSON.stringify({ 0: ["variables.data.kuva.upload"] })
    );
    formData.append("0", values.kuva);
    return formData;
  };

  const lahetaPyynto = async (body: FormData | string): Promise<string> => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL!, {
        method: "POST",
        headers:
          body instanceof FormData
            ? {}
            : {
                "Content-Type": "application/json",
              },
        credentials: "include",
        body,
      });

      if (response.ok) {
        const { data } = await response.json();
        return data.item.id;
      }
    } catch (error) {
      console.error(error);
    }

    return "";
  };

  const kasitteleAvaa = () => {
    asetaAuki(true);
  };

  const kasitteleSulje = (lopputulos = KoneEnum.Peruutettu) => {
    asetaAuki(false);
    if (
      lopputulos === KoneEnum.Peruutettu ||
      lopputulos === KoneEnum.LisaaminenOnnistui
    ) {
      formik.resetForm();
      dispatch({ type: "nollaa" });
    }
    callback(lopputulos);
  };

  if (!data) return null;

  return (
    <>
      <Button variant={variant} onClick={kasitteleAvaa} sx={sx}>
        {value}
      </Button>
      <Dialog
        open={auki}
        keepMounted
        onClose={() => kasitteleSulje()}
        fullScreen={fullScreen}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <TextField
              name="nimi"
              label="Nimi"
              value={formik.values.nimi}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.nimi && !!formik.errors.nimi}
              helperText={formik.touched.nimi && formik.errors.nimi}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              name="merkki"
              label="Merkki"
              value={formik.values.merkki}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.merkki && !!formik.errors.merkki}
              helperText={
                formik.touched.merkki ? formik.errors.merkki : "Esim. Valtra"
              }
              fullWidth
              margin="normal"
            />
            <TextField
              name="malli"
              label="Malli"
              value={formik.values.malli}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.malli && !!formik.errors.malli}
              helperText={
                formik.touched.malli ? formik.errors.malli : "Esim. 8950"
              }
              fullWidth
              margin="normal"
            />
            <TextField
              name="rekisterinumero"
              label="Rekisterinumero"
              value={formik.values.rekisterinumero}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.rekisterinumero &&
                !!formik.errors.rekisterinumero
              }
              helperText={
                formik.touched.rekisterinumero
                  ? formik.errors.rekisterinumero
                  : "FTPA:n antama rekisterinumero, esim. MOFIN123"
              }
              fullWidth
              margin="normal"
            />
            <FormGroup>
              <FormLabel>Luokat</FormLabel>
              {data.luokkas.map((luokka: Luokka) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="luokat"
                      value={luokka.id}
                      checked={formik.values.luokat.includes(luokka.id)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label={luokka.nimi}
                  key={luokka.id}
                />
              ))}
            </FormGroup>
            <Typography variant="h6" sx={{ margin: "0.5rem 0" }}>
              Tekniset tiedot
            </Typography>
            <TextField
              name="moottoritiedot"
              label="Moottoritiedot"
              value={formik.values.moottoritiedot}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.moottoritiedot && !!formik.errors.moottoritiedot
              }
              helperText={
                formik.touched.moottoritiedot && formik.errors.moottoritiedot
              }
              fullWidth
              margin="normal"
              multiline
              rows={2}
            />
            <TextField
              type="number"
              name="maksimiRpm"
              label="RPM (maksimi)"
              value={formik.values.maksimiRpm}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.maksimiRpm && !!formik.errors.maksimiRpm}
              helperText={formik.touched.maksimiRpm && formik.errors.maksimiRpm}
              fullWidth
              margin="normal"
            />
            <TextField
              name="voimansiirtotiedot"
              label="Voimansiirtotiedot"
              value={formik.values.voimansiirtotiedot}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.voimansiirtotiedot &&
                !!formik.errors.voimansiirtotiedot
              }
              helperText={
                formik.touched.voimansiirtotiedot &&
                formik.errors.voimansiirtotiedot
              }
              fullWidth
              margin="normal"
              multiline
              rows={2}
            />
            <TextField
              name="lisatiedot"
              label="Lisätiedot"
              value={formik.values.lisatiedot}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lisatiedot && !!formik.errors.lisatiedot}
              helperText={formik.touched.lisatiedot && formik.errors.lisatiedot}
              fullWidth
              margin="normal"
              multiline
              rows={2}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "320px",
              }}
            >
              <FormLabel component="label" htmlFor="kuva">
                Kuva
              </FormLabel>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  component="img"
                  src={state.url}
                  alt="Traktorin kuva"
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "4px",
                    maxWidth: "100px",
                  }}
                />
                <Button
                  variant="contained"
                  component="label"
                  sx={{ margin: "0 0.5rem" }}
                >
                  {state.status === KuvaEnum.Ei ||
                  state.status === KuvaEnum.Poistettu
                    ? "Valitse kuva"
                    : "Vaihda"}
                  <input
                    type="file"
                    name="kuva"
                    id="kuva"
                    accept="image/*"
                    hidden
                    onChange={(event) => {
                      if (event.target.files && event.target.files[0]) {
                        formik.setFieldValue("kuva", event.target.files[0]);
                        dispatch({
                          type: "lisaa",
                          payload: {
                            url: URL.createObjectURL(event.target.files[0]),
                          },
                        });
                        event.target.value = "";
                      }
                    }}
                  />
                </Button>
                {(state.status === KuvaEnum.On ||
                  state.status === KuvaEnum.Lisatty ||
                  state.status === KuvaEnum.Poistettu) && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      formik.setFieldValue("kuva", undefined);
                      dispatch({
                        type:
                          state.status === KuvaEnum.On ? "poista" : "nollaa",
                      });
                    }}
                  >
                    {state.status === KuvaEnum.On ? "Poista" : "Peruuta"}
                  </Button>
                )}
              </Box>
            </Box>
            <Typography variant="h6" sx={{ margin: "0.5rem 0" }}>
              Yhteistyökumppanit
            </Typography>
            <Yhteistyokumppanit
              kone={kone}
              callback={asetaYhteistyokumppanitCallback}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => kasitteleSulje()}>Peruuta</Button>
          <Button onClick={() => formik.handleSubmit()}>Tallenna</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KoneenLisaysTaiMuokkaus;
