import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import { useAuth } from "../contexts/AuthContext";
import {
  KIRJAUDU_ULOS_MUTATION,
  KIRJAUTUNUT_KAYTTAJA_QUERY,
} from "../graphql/queries";

export default function Uloskirjautuminen() {
  const { setUser } = useAuth();
  const [kirjauduUlos, { data }] = useMutation(KIRJAUDU_ULOS_MUTATION, {
    refetchQueries: [{ query: KIRJAUTUNUT_KAYTTAJA_QUERY }],
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data?.endSession) {
      setUser(null);
      navigate("/");
    }
  }, [data, setUser, navigate]);

  return (
    <Button
      onClick={(_: React.SyntheticEvent) => kirjauduUlos()}
      variant="outlined"
    >
      Kirjaudu ulos
    </Button>
  );
}
