import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { Osakilpailu, Luokka } from "../types";

interface OsakilpailujenValintaProps {
  valitut: Osakilpailu[];
  valitutLuokat: Luokka[];
  callback: React.Dispatch<React.SetStateAction<Osakilpailu[]>>;
}

const ilmoittautuminenOnVoimassa = (viimeinenIlmoittautumispaiva: Date) =>
  new Date() <= viimeinenIlmoittautumispaiva;

const OsakilpailujenValinta: React.FC<OsakilpailujenValintaProps> = ({
  valitut,
  valitutLuokat,
  callback,
}) => {
  const luokkienOsakilpailut = valitutLuokat
    .flatMap((luokka) => luokka.osakilpailut)
    .sort((a, b) => a.nimi.localeCompare(b.ajankohta))
    .filter(
      (osakilpailu, index, self) =>
        index === self.findIndex((t) => t.id === osakilpailu.id)
    );

  const kasitteleValinta = (
    event: React.ChangeEvent<HTMLInputElement>,
    osakilpailu: Osakilpailu
  ) => {
    if (event.target.checked) {
      callback([...valitut, osakilpailu]);
    } else {
      callback(valitut.filter((valittu) => valittu.id !== osakilpailu.id));
    }
  };

  return (
    <>
      <FormGroup>
        {luokkienOsakilpailut.map((osakilpailu) => {
          const viimeinenIlmoittautumispaiva = new Date(
            osakilpailu.viimeinenIlmoittautumispaiva
          );
          const disabled = !ilmoittautuminenOnVoimassa(
            viimeinenIlmoittautumispaiva
          );
          const isChecked = valitut.some(
            (valittu) => valittu.id === osakilpailu.id
          );

          return (
            <React.Fragment key={osakilpailu.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={osakilpailu.id}
                    checked={isChecked && !disabled}
                    onChange={(e) => kasitteleValinta(e, osakilpailu)}
                    disabled={disabled}
                  />
                }
                label={
                  new Date(osakilpailu.ajankohta).toLocaleDateString("fi-FI") +
                  " " +
                  osakilpailu.nimi
                }
              />
              {disabled && (
                <FormHelperText>
                  Kilpailun ilmoittautumisaika ei ole enää voimassa. Ota
                  yhteyttä kisajärjestäjään.
                </FormHelperText>
              )}
            </React.Fragment>
          );
        })}
      </FormGroup>
    </>
  );
};

export default OsakilpailujenValinta;
