import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { KONEET_QUERY } from "../graphql/queries";
import { KonePick, User, Kone, Luokka } from "../types";

interface KoneenValintaProps {
  valittu: KonePick;
  user: User;
  callback: React.Dispatch<React.SetStateAction<KonePick>>;
}

const KoneenValinta: React.FC<KoneenValintaProps> = ({
  valittu,
  user,
  callback,
}) => {
  const { data } = useQuery(KONEET_QUERY, { variables: { id: user.id } });
  const [valittuKone, asetaValittuKone] = useState(valittu.id);

  useEffect(() => {
    data && !valittuKone && asetaValittuKone(data.kones[0].id);
    if (!data || !valittuKone) return;
    const nimi = data.kones.find((kone: Kone) => kone.id === valittuKone)?.nimi;
    const luokat = data.kones
      .find((kone: Kone) => kone.id === valittuKone)
      .luokat.map((luokka: Luokka) => luokka.id);
    callback({ id: valittuKone, nimi: nimi, luokat: luokat });
  }, [data, valittuKone, callback]);

  const kasitteleValinta = (event: React.ChangeEvent<HTMLInputElement>) => {
    asetaValittuKone(event.target.value);
  };

  return (
    <>
      <FormControl>
        <RadioGroup>
          {data?.kones.map((kone: Kone) => (
            <FormControlLabel
              control={
                <Radio
                  value={kone.id}
                  checked={!!(kone.id === valittuKone)}
                  onChange={kasitteleValinta}
                />
              }
              label={kone.nimi}
              key={kone.id}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default KoneenValinta;
