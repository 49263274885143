import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { PAIVITA_SALASANA_MUTATION } from "../graphql/queries";
import { SalasananPaivitysEnum } from "../enums/SalasananPaivitysEnum";

interface SalasananPaivitysProps {
  email: string;
  token: string;
}

const theme = createTheme();

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Salasanan on oltava vähintään kahdeksan merkkiä pitkä")
    .required("Tämä on pakollinen kenttä"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Salasanojen on täsmättävä")
    .required("Tämä on pakollinen kenttä"),
});

const SalasananPaivitys: React.FC<SalasananPaivitysProps> = ({
  email,
  token,
}) => {
  const [lopputulos, asetaLopputulos] = React.useState(0);
  const [naytaSalasana, asetaNaytaSalasana] = React.useState(false);
  const [paivitaSalasana] = useMutation(PAIVITA_SALASANA_MUTATION);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await paivitaSalasana({
          variables: {
            email: email,
            password: values.password,
            token: token,
          },
        });
        navigate({ pathname: "/kirjaudu", search: "?paivitys=1" });
      } catch {
        asetaLopputulos(SalasananPaivitysEnum.Epaonnistui);
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Aseta uusi salasana
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            {lopputulos === SalasananPaivitysEnum.Epaonnistui && (
              <Alert severity="error" sx={{ mb: 1 }}>
                Salasanan asettaminen epäonnistui
              </Alert>
            )}
            <TextField
              type={naytaSalasana ? "text" : "password"}
              name="password"
              label="Salasana"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="new-password"
              fullWidth
              margin="normal"
              required
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => asetaNaytaSalasana((nayta) => !nayta)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {naytaSalasana ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={naytaSalasana ? "text" : "password"}
              name="confirmPassword"
              label="Vahvista salasana"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              margin="normal"
              required
              error={
                formik.touched.confirmPassword &&
                !!formik.errors.confirmPassword
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => asetaNaytaSalasana((nayta) => !nayta)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {naytaSalasana ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Aseta salasana
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SalasananPaivitys;
