import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import KoneenValinta from "./KoneenValinta";
import LuokkienValinta from "./LuokkienValinta";
import OsakilpailujenValinta from "./OsakilpailujenValinta";
import KuljettajanValinta from "./KuljettajanValinta";
import IlmoittautumisenYhteenveto from "./IlmoittautumisenYhteenveto";
import {
  LISAA_ILMOITTAUTUMISET_MUTATION,
  LISAA_KOOSTE_MUTATION,
} from "../graphql/queries";
import { IlmoittautuminenEnum } from "../enums/IlmoittautuminenEnum";
import {
  User,
  KonePick,
  Luokka,
  Osakilpailu,
  KoostettuIlmoittautuminen,
} from "../types";

interface IlmoittautumistenLisaysProps {
  user: User;
  callback: (lopputulos: IlmoittautuminenEnum) => void;
}

const steps = [
  {
    label: "Valitse kone",
  },
  {
    label: "Valitse luokat",
  },
  {
    label: "Valitse osakilpailut",
  },
  {
    label: "Valitse kuljettaja",
  },
  {
    label: "Hyväksy ilmoittautuminen",
  },
];

const IlmoittautumistenLisays: React.FC<IlmoittautumistenLisaysProps> = ({
  user,
  callback,
}) => {
  const [aktiivinenVaihe, asetaAktiivinenVaihe] = useState(0);
  const [valittuKone, asetaValittuKone] = useState<KonePick>({
    id: "",
    nimi: "",
    luokat: [],
  });
  const [valitutLuokat, asetaValitutLuokat] = useState<Luokka[]>([]);
  const [valitutOsakilpailut, asetaValitutOsakilpailut] = useState<
    Osakilpailu[]
  >([]);
  const [valittuKuljettaja, asetaValittuKuljettaja] = useState(user);
  const [lisaaIlmoittautumiset] = useMutation(LISAA_ILMOITTAUTUMISET_MUTATION);
  const [lisaaKooste] = useMutation(LISAA_KOOSTE_MUTATION);

  const kasitteleSeuraava = () => {
    asetaAktiivinenVaihe(
      (edellinenAktiivinenVaihe) => edellinenAktiivinenVaihe + 1
    );
    aktiivinenVaihe === steps.length - 1 && kasitteleIlmoittautumistenLisays();
  };

  const kasitteleEdellinen = () => {
    asetaAktiivinenVaihe(
      (edellinenAktiivinenVaihe) => edellinenAktiivinenVaihe - 1
    );
  };

  const kasitteleIlmoittautumistenLisays = async () => {
    const koostetutIlmoittautumiset = koostaIlmoittautumiset();
    try {
      const response = await lisaaIlmoittautumiset({
        variables: { data: koostetutIlmoittautumiset },
      });
      const ids = response?.data?.createIlmoittautuminens.map(
        (item: { id: string }) => ({
          id: item.id,
        })
      );
      await lisaaKooste({
        variables: { data: { ilmoittautumiset: { connect: ids } } },
      });
      kasitteleTyhjenna(IlmoittautuminenEnum.LisaaminenOnnistui);
    } catch {
      kasitteleTyhjenna(IlmoittautuminenEnum.LisaaminenEpaonnistui);
    }
  };

  const koostaIlmoittautumiset = () => {
    const koostetutIlmoittautumiset: KoostettuIlmoittautuminen[] = [];
    valitutLuokat.forEach((valittuLuokka) => {
      valitutOsakilpailut.forEach((valittuOsakilpailu) => {
        const osakilpailu = valittuLuokka.osakilpailut.find(
          (osakilpailu) => osakilpailu.id === valittuOsakilpailu.id
        );
        if (osakilpailu) {
          const koostettuIlmoittautuminen = koostaIlmoittautuminen(
            valittuKone.id,
            valittuLuokka.id,
            valittuOsakilpailu.id,
            valittuKuljettaja.id
          );
          koostetutIlmoittautumiset.push(koostettuIlmoittautuminen);
        }
      });
    });
    return koostetutIlmoittautumiset;
  };

  const koostaIlmoittautuminen = (
    kone: string,
    luokka: string,
    osakilpailu: string,
    kuljettaja: string
  ) => ({
    kone: {
      connect: {
        id: kone,
      },
    },
    luokka: {
      connect: {
        id: luokka,
      },
    },
    osakilpailu: {
      connect: {
        id: osakilpailu,
      },
    },
    user: {
      connect: {
        id: kuljettaja,
      },
    },
  });

  const kasitteleTyhjenna = (lopputulos = 0) => {
    asetaValittuKuljettaja(user);
    asetaValitutOsakilpailut([]);
    asetaValitutLuokat([]);
    asetaValittuKone({
      id: "",
      nimi: "",
      luokat: [],
    });
    asetaAktiivinenVaihe(0);
    callback(lopputulos);
  };

  const validoiVaihe = () => {
    switch (aktiivinenVaihe) {
      case 1:
        return !!Object.keys(valitutLuokat).length;
      case 2:
        return !!valitutOsakilpailut.length;
      default:
        return true;
    }
  };

  return (
    <Stepper activeStep={aktiivinenVaihe} orientation="vertical">
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel>{step.label}</StepLabel>
          <StepContent>
            {index === 0 ? (
              <KoneenValinta
                valittu={valittuKone}
                user={user}
                callback={asetaValittuKone}
              />
            ) : index === 1 ? (
              <LuokkienValinta
                valitut={valitutLuokat}
                koneenLuokat={valittuKone.luokat}
                callback={asetaValitutLuokat}
              />
            ) : index === 2 ? (
              <OsakilpailujenValinta
                valitut={valitutOsakilpailut}
                valitutLuokat={valitutLuokat}
                callback={asetaValitutOsakilpailut}
              />
            ) : index === 3 ? (
              <KuljettajanValinta
                valittu={valittuKuljettaja}
                callback={asetaValittuKuljettaja}
              />
            ) : (
              <IlmoittautumisenYhteenveto
                valittuKone={valittuKone}
                valitutLuokat={valitutLuokat}
                valitutOsakilpailut={valitutOsakilpailut}
                valittuKuljettaja={valittuKuljettaja}
              />
            )}
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  disabled={!validoiVaihe()}
                  variant="contained"
                  onClick={kasitteleSeuraava}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {index === steps.length - 1 ? "Ilmoittaudu" : "Jatka"}
                </Button>
                <Button
                  disabled={index === 0}
                  onClick={kasitteleEdellinen}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Takaisin
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default IlmoittautumistenLisays;
