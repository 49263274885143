import { useQuery } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { KAYTTAJAT_QUERY } from "../graphql/queries";
import React from "react";
import { User } from "../types";

interface KuljettajanValintaProps {
  valittu: User;
  callback: React.Dispatch<React.SetStateAction<User>>;
}

const KuljettajanValinta: React.FC<KuljettajanValintaProps> = ({
  valittu: valittuKuljettaja,
  callback,
}) => {
  const { data } = useQuery(KAYTTAJAT_QUERY);

  if (!data) return null;

  return (
    <>
      <Autocomplete
        value={valittuKuljettaja}
        options={data.users}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        onChange={(_, value) => value && callback(value)}
        renderInput={(params) => <TextField {...params} label="Kuljettaja" />}
      />
    </>
  );
};

export default KuljettajanValinta;
