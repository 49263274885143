import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  KIRJAUDU_SISAAN_MUTATION,
  KIRJAUTUNUT_KAYTTAJA_QUERY,
} from "../graphql/queries";

const theme = createTheme();

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Anna kelvollinen sähköpostiosoite")
    .required("Tämä on pakollinen kenttä"),
  password: Yup.string().required("Tämä on pakollinen kenttä"),
});

export default function Sisaankirjautuminen() {
  const [searchParams] = useSearchParams();
  const rekisteroityminen = searchParams.get("rekisteroityminen");
  const paivitys = searchParams.get("paivitys");
  const [naytaSalasana, asetaNaytaSalasana] = useState(false);

  const [kirjauduSisaan, { data }] = useMutation(KIRJAUDU_SISAAN_MUTATION, {
    refetchQueries: [{ query: KIRJAUTUNUT_KAYTTAJA_QUERY }],
  });

  const navigate = useNavigate();

  const virhe =
    data?.authenticateUserWithPassword.__typename ===
    "UserAuthenticationWithPasswordFailure"
      ? data?.authenticateUserWithPassword
      : undefined;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const vastaus = await kirjauduSisaan({ variables: values });
      vastaus?.data?.authenticateUserWithPassword.__typename ===
        "UserAuthenticationWithPasswordSuccess" &&
        navigate("/ilmoittautumiset");
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Kirjaudu sisään
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={formik.handleSubmit}
          >
            {rekisteroityminen && (
              <Alert severity="success" sx={{ mb: 1 }}>
                Rekisteröityminen onnistui. Jatka kirjautumalla sisään.
              </Alert>
            )}
            {paivitys && (
              <Alert severity="success" sx={{ mb: 1 }}>
                Salasanan asettaminen onnistui. Jatka kirjautumalla sisään.
              </Alert>
            )}
            {virhe && (
              <Alert severity="error" sx={{ mb: 1 }}>
                Kirjautuminen epäonnistui
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Sähköposti"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              autoComplete="email"
              autoFocus={!rekisteroityminen}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => asetaNaytaSalasana((nayta) => !nayta)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {naytaSalasana ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={naytaSalasana ? "text" : "password"}
              name="password"
              label="Salasana"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              autoComplete="current-password"
              fullWidth
              margin="normal"
              required
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Kirjaudu
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/paivita-salasana" variant="body2">
                  Unohditko salasanasi?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/rekisteroidy" variant="body2">
                  Rekisteröidy
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
