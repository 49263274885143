import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  ILMOITTAUTUMISET_JA_KONEET_QUERY,
  POISTA_KONE_MUTATION,
} from "../graphql/queries";
import { KoneEnum } from "../enums/KoneEnum";
import { User } from "../types";

interface KoneenPoistoProps {
  id: string;
  user: User;
  callback: (lopputulos: KoneEnum) => void;
}

const KoneenPoisto: React.FC<KoneenPoistoProps> = ({ id, user, callback }) => {
  const [auki, asetaAuki] = useState(false);

  const [poistaKone] = useMutation(POISTA_KONE_MUTATION);

  const kasitteleAvaa = () => {
    asetaAuki(true);
  };

  const kasitteleSulje = (lopputulos = KoneEnum.Peruutettu) => {
    asetaAuki(false);
    callback(lopputulos);
  };

  const kasittelePoistaKone = async () => {
    try {
      await poistaKone({
        variables: {
          where: [
            {
              id: id,
            },
          ],
        },
        refetchQueries: [
          {
            query: ILMOITTAUTUMISET_JA_KONEET_QUERY,
            variables: { id: user.id },
          },
        ],
      });
      kasitteleSulje(KoneEnum.PoistaminenOnnistui);
    } catch {
      kasitteleSulje(KoneEnum.PoistaminenEpaonnistui);
    }
  };

  return (
    <div>
      <Button variant="outlined" onClick={kasitteleAvaa}>
        Poista
      </Button>
      <Dialog open={auki} keepMounted onClose={() => kasitteleSulje()}>
        <DialogTitle>Poista kone</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Haluatko varmasti poistaa tämän koneen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => kasitteleSulje()}>Peruuta</Button>
          <Button onClick={() => kasittelePoistaKone()}>Poista</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default KoneenPoisto;
