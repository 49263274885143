import { useState, useReducer, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { print } from "graphql";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../contexts/AuthContext";
import {
  ALASEURAT_QUERY,
  KAYTTAJA_QUERY,
  MUOKKAA_KAYTTAJAA_MUTATION,
} from "../graphql/queries";
import { KayttajatiliEnum } from "../enums/KayttajatiliEnum";
import {
  Action,
  KayttajaLomake,
  KayttajanMuokkausOperaatio,
  State,
} from "../types";
import {
  validationSchema,
  validationSchemaPassword,
} from "../validationSchemas";
import { KuvaEnum } from "../enums/KuvaEnum";
import FormLabel from "@mui/material/FormLabel";

interface Alaseura {
  id: string;
  nimi: string;
}

export default function Kayttajatili() {
  const { user } = useAuth();

  const initialState: State = {
    status: user?.kuva?.url ? KuvaEnum.On : KuvaEnum.Ei,
    url: user?.kuva?.url || "placeholder.svg",
  };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "lisaa":
        return {
          status: KuvaEnum.Lisatty,
          url: action.payload?.url || state.url,
        };
      case "poista":
        return { status: KuvaEnum.Poistettu, url: "placeholder.svg" };
      case "nollaa":
        return initialState;
      default:
        throw new Error();
    }
  };

  const { data: dataAlaseurat } = useQuery(ALASEURAT_QUERY);
  const [lopputulos, asetaLopputulos] = useState(0);
  const [naytaSalasana, asetaNaytaSalasana] = useState(false);
  const [salasananVaihto, asetaSalasananVaihto] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const [haeKayttaja, { data, refetch }] = useLazyQuery(KAYTTAJA_QUERY, {
    onCompleted(data) {
      const { id, __typename, ...muut } = data.users[0];
      formik.setValues({
        ...muut,
        password: "",
        alaseura: data.users[0].alaseura.id,
        confirmPassword: "",
      });
    },
  });

  useEffect(() => {
    dispatch({ type: "nollaa" });
  }, [user?.kuva?.url]);

  useEffect(() => {
    user && haeKayttaja({ variables: { id: user.id } });
  }, [user, haeKayttaja]);

  const formik = useFormik<KayttajaLomake>({
    initialValues: {
      email: "",
      password: "",
      name: "",
      puhelinnumero: "",
      katuosoite: "",
      postinumero: 0,
      postitoimipaikka: "",
      tilinumero: "",
      tiimi: "",
      kuva: undefined,
      alaseura: "",
      confirmPassword: "",
    },
    validationSchema: !salasananVaihto
      ? validationSchema
      : validationSchemaPassword,
    onSubmit: async (values) => {
      try {
        await muokkaaKayttajaa(values);
        asetaLopputulos(KayttajatiliEnum.Onnistui);
        refetch();
      } catch {
        asetaLopputulos(KayttajatiliEnum.Epaonnistui);
      }
      asetaSalasananVaihto(false);
      siirryYlos();
    },
  });

  const muokkaaKayttajaa = async (values: KayttajaLomake): Promise<void> => {
    const operations = muodostaOperaatio(values);
    const body = muodostaBody(values, operations);
    await lahetaPyynto(body);
  };

  const muodostaOperaatio = (
    values: KayttajaLomake
  ): KayttajanMuokkausOperaatio => {
    const { confirmPassword, password, ...muut } = values;
    const uusiSalasana = salasananVaihto ? { password } : {};
    return {
      query: print(MUOKKAA_KAYTTAJAA_MUTATION),
      variables: {
        id: data?.users[0].id || user?.id,
        data: {
          ...muut,
          ...uusiSalasana,
          kuva: values.kuva
            ? {
                upload: null,
              }
            : null,
          alaseura: {
            connect: {
              id: muut.alaseura,
            },
          },
        },
      },
    };
  };

  const muodostaBody = (
    values: KayttajaLomake,
    operations: KayttajanMuokkausOperaatio
  ): FormData | string => {
    if (!values.kuva) return JSON.stringify(operations);
    const formData = new FormData();
    formData.append("operations", JSON.stringify(operations));
    formData.append(
      "map",
      JSON.stringify({ 0: ["variables.data.kuva.upload"] })
    );
    formData.append("0", values.kuva);
    return formData;
  };

  const lahetaPyynto = async (body: FormData | string): Promise<void> => {
    try {
      await fetch(process.env.REACT_APP_API_URL!, {
        method: "POST",
        headers:
          body instanceof FormData
            ? {}
            : {
                "Content-Type": "application/json",
              },
        credentials: "include",
        body,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const kasitteleSalasananVaihto = () => {
    asetaSalasananVaihto((previousPasswordChange) => !previousPasswordChange);
  };

  const palautaIlmoitus = () => {
    switch (lopputulos) {
      case KayttajatiliEnum.Onnistui:
        return (
          <Alert severity="success" sx={{ marginBottom: "1rem" }}>
            Käyttäjätilin muokkaaminen onnistui
          </Alert>
        );
      case KayttajatiliEnum.Epaonnistui:
        return (
          <Alert severity="error" sx={{ marginBottom: "1rem" }}>
            Käyttäjätilin muokkaaminen epäonnistui
          </Alert>
        );
    }
  };

  const siirryYlos = () => {
    window.scrollTo(0, 0);
  };

  if (!data || !user) return null;

  return (
    <>
      <Typography component="h1" variant="h5">
        Käyttäjätili
      </Typography>
      {lopputulos !== 0 && palautaIlmoitus()}
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <TextField
          name="email"
          label="Sähköposti"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
          margin="normal"
          required
        />
        <Button variant="outlined" onClick={kasitteleSalasananVaihto}>
          Aseta uusi salasana
        </Button>
        {salasananVaihto && (
          <>
            <TextField
              id="password"
              type={naytaSalasana ? "text" : "password"}
              name="password"
              label="Uusi salasana"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => asetaNaytaSalasana((nayta) => !nayta)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {naytaSalasana ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={naytaSalasana ? "text" : "password"}
              name="confirmPassword"
              label="Vahvista uusi salasana"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword &&
                !!formik.errors.confirmPassword
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              fullWidth
              margin="normal"
              required={!!(formik.touched.password && formik.values.password)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => asetaNaytaSalasana((nayta) => !nayta)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {naytaSalasana ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        <TextField
          name="name"
          label="Nimi"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="puhelinnumero"
          label="Puhelinnumero"
          value={formik.values.puhelinnumero}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.puhelinnumero && !!formik.errors.puhelinnumero}
          helperText={
            formik.touched.puhelinnumero && formik.errors.puhelinnumero
          }
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="katuosoite"
          label="Katuosoite"
          value={formik.values.katuosoite}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.katuosoite && !!formik.errors.katuosoite}
          helperText={formik.touched.katuosoite && formik.errors.katuosoite}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          type="number"
          name="postinumero"
          label="Postinumero"
          value={formik.values.postinumero}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.postinumero && !!formik.errors.postinumero}
          helperText={formik.touched.postinumero && formik.errors.postinumero}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="postitoimipaikka"
          label="Postitoimipaikka"
          value={formik.values.postitoimipaikka}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.postitoimipaikka && !!formik.errors.postitoimipaikka
          }
          helperText={
            formik.touched.postitoimipaikka && formik.errors.postitoimipaikka
          }
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="tilinumero"
          label="Tilinumero"
          value={formik.values.tilinumero}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.tilinumero && !!formik.errors.tilinumero}
          helperText={formik.touched.tilinumero && formik.errors.tilinumero}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="tiimi"
          label="Tiimi"
          value={formik.values.tiimi}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.tiimi && !!formik.errors.tiimi}
          helperText={formik.touched.tiimi && formik.errors.tiimi}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          select
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="alaseura"
          label="Alaseura"
          name="alaseura"
          value={formik.values.alaseura}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.alaseura && !!formik.errors.alaseura}
          helperText={formik.touched.alaseura && formik.errors.alaseura}
        >
          {dataAlaseurat?.alaseuras.map((alaseura: Alaseura) => (
            <MenuItem key={alaseura.id} value={alaseura.id}>
              <ListItemText primary={alaseura.nimi} />
            </MenuItem>
          ))}
        </TextField>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "320px",
          }}
        >
          <FormLabel component="label" htmlFor="kuva">
            Kuva
          </FormLabel>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Box
              component="img"
              src={state.url}
              alt="Traktorin kuva"
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "4px",
                maxWidth: "100px",
              }}
            />
            <Button
              variant="contained"
              component="label"
              sx={{ margin: "0 0.5rem" }}
            >
              {state.status === KuvaEnum.Ei ||
              state.status === KuvaEnum.Poistettu
                ? "Valitse kuva"
                : "Vaihda"}
              <input
                type="file"
                name="kuva"
                id="kuva"
                accept="image/*"
                hidden
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    formik.setFieldValue("kuva", event.target.files[0]);
                    dispatch({
                      type: "lisaa",
                      payload: {
                        url: URL.createObjectURL(event.target.files[0]),
                      },
                    });
                    event.target.value = "";
                  }
                }}
              />
            </Button>
            {(state.status === KuvaEnum.On ||
              state.status === KuvaEnum.Lisatty ||
              state.status === KuvaEnum.Poistettu) && (
              <Button
                variant="contained"
                onClick={() => {
                  formik.setFieldValue("kuva", undefined);
                  dispatch({
                    type: state.status === KuvaEnum.On ? "poista" : "nollaa",
                  });
                }}
              >
                {state.status === KuvaEnum.On ? "Poista" : "Peruuta"}
              </Button>
            )}
          </Box>
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          Päivitä
        </Button>
      </Box>
    </>
  );
}
