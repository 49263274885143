import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ALASEURAT_QUERY, REKISTEROIDY_MUTATION } from "../graphql/queries";
import { RekisteroityminenEnum } from "../enums/RekisteroityminenEnum";
import { validationSchemaPassword } from "../validationSchemas";

interface Alaseura {
  id: string;
  nimi: string;
}

const theme = createTheme();

export default function Rekisteroityminen() {
  const { data } = useQuery(ALASEURAT_QUERY);
  const [lopputulos, asetaLopputulos] = useState(0);
  const [naytaSalasana, asetaNaytaSalasana] = useState(false);
  const [rekisteroidy] = useMutation(REKISTEROIDY_MUTATION);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      puhelinnumero: "",
      katuosoite: "",
      postinumero: 0,
      postitoimipaikka: "",
      tilinumero: "",
      tiimi: "",
      alaseura: "",
      confirmPassword: "",
    },
    validationSchema: validationSchemaPassword,
    onSubmit: async (values) => {
      const { confirmPassword, ...muut } = values;
      try {
        await rekisteroidy({
          variables: {
            data: {
              ...muut,
              alaseura: {
                connect: {
                  id: muut.alaseura,
                },
              },
            },
          },
        });
        navigate({
          pathname: "/kirjaudu",
          search: "?rekisteroityminen=1",
        });
      } catch {
        asetaLopputulos(RekisteroityminenEnum.Epaonnistui);
      }
    },
  });

  if (!data) return null;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Rekisteröidy
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
            sx={{ mt: 1 }}
          >
            {lopputulos === RekisteroityminenEnum.Epaonnistui && (
              <Alert severity="error" sx={{ mb: 1 }}>
                Rekisteröityminen epäonnistui
              </Alert>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Sähköposti"
              name="email"
              autoComplete="email"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Salasana"
              name="password"
              type={naytaSalasana ? "text" : "password"}
              autoComplete="new-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => asetaNaytaSalasana((nayta) => !nayta)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {naytaSalasana ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label="Vahvista salasana"
              name="confirmPassword"
              type={naytaSalasana ? "text" : "password"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword &&
                !!formik.errors.confirmPassword
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => asetaNaytaSalasana((nayta) => !nayta)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {naytaSalasana ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nimi"
              name="name"
              autoComplete="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="puhelinnumero"
              label="Puhelinnumero"
              name="puhelinnumero"
              autoComplete="puhelinnumero"
              value={formik.values.puhelinnumero}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.puhelinnumero && !!formik.errors.puhelinnumero
              }
              helperText={
                formik.touched.puhelinnumero && formik.errors.puhelinnumero
              }
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="katuosoite"
              label="Katuosoite"
              name="katuosoite"
              autoComplete="katuosoite"
              value={formik.values.katuosoite}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.katuosoite && !!formik.errors.katuosoite}
              helperText={formik.touched.katuosoite && formik.errors.katuosoite}
            />
            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="postinumero"
              label="Postinumero"
              name="postinumero"
              autoComplete="postinumero"
              value={formik.values.postinumero}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.postinumero && !!formik.errors.postinumero}
              helperText={
                formik.touched.postinumero && formik.errors.postinumero
              }
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="postitoimipaikka"
              label="Postitoimipaikka"
              name="postitoimipaikka"
              autoComplete="postitoimipaikka"
              value={formik.values.postitoimipaikka}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postitoimipaikka &&
                !!formik.errors.postitoimipaikka
              }
              helperText={
                formik.touched.postitoimipaikka &&
                formik.errors.postitoimipaikka
              }
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="tilinumero"
              label="Tilinumero"
              name="tilinumero"
              autoComplete="tilinumero"
              value={formik.values.tilinumero}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.tilinumero && !!formik.errors.tilinumero}
              helperText={formik.touched.tilinumero && formik.errors.tilinumero}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="tiimi"
              label="Tiimi"
              name="tiimi"
              autoComplete="tiimi"
              value={formik.values.tiimi}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.tiimi && !!formik.errors.tiimi}
              helperText={formik.touched.tiimi && formik.errors.tiimi}
            />
            <TextField
              select
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="alaseura"
              label="Alaseura"
              name="alaseura"
              value={formik.values.alaseura}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.alaseura && !!formik.errors.alaseura}
              helperText={formik.touched.alaseura && formik.errors.alaseura}
            >
              {data?.alaseuras.map((alaseura: Alaseura) => (
                <MenuItem key={alaseura.id} value={alaseura.id}>
                  <ListItemText primary={alaseura.nimi} />
                </MenuItem>
              ))}
            </TextField>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Rekisteröidy
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
