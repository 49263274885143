import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { KONEEN_LUOKAT_QUERY } from "../graphql/queries";
import { Luokka } from "../types";

interface LuokkienValintaProps {
  valitut: Luokka[];
  koneenLuokat: Luokka[];
  callback: React.Dispatch<React.SetStateAction<Luokka[]>>;
}

const LuokkienValinta: React.FC<LuokkienValintaProps> = ({
  valitut,
  koneenLuokat,
  callback,
}) => {
  const { data } = useQuery(KONEEN_LUOKAT_QUERY, {
    variables: {
      luokat: koneenLuokat,
      kuluvaVuosi: new Date(new Date().getFullYear(), 0, 1),
    },
  });
  const [valitutLuokat, asetaValitutLuokat] = useState(
    valitut.map((valittu) => valittu.id)
  );

  useEffect(() => {
    if (!data) return;
    const luokat = data.luokkas.filter(
      (luokka: Luokka) =>
        luokka.osakilpailut.length > 0 && valitutLuokat.includes(luokka.id)
    );
    callback(luokat);
  }, [valitutLuokat, data, callback]);

  const kasitteleValinta = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked
      ? asetaValitutLuokat((valitut) => [...valitut, event.target.value])
      : asetaValitutLuokat((valitut) => [
          ...valitut.filter((valittu) => valittu !== event.target.value),
        ]);
  };

  return (
    <>
      <FormGroup>
        {data?.luokkas.map((luokka: Luokka) => (
          <FormControlLabel
            control={
              <Checkbox
                value={luokka.id}
                checked={valitutLuokat.includes(luokka.id)}
                onChange={kasitteleValinta}
              />
            }
            label={luokka.nimi}
            key={luokka.id}
          />
        ))}
      </FormGroup>
    </>
  );
};

export default LuokkienValinta;
