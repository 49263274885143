import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SalasananPaivitys from "./SalasananPaivitys";
import { LAHETA_LINKKI_SALASANAN_PAIVITTAMISEKSI_MUTATION } from "../graphql/queries";

const theme = createTheme();

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Anna kelvollinen sähköpostiosoite")
    .required("Tämä on pakollinen kenttä"),
});

export default function UnohtunutSalasana() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const [lahetetty, asetaLahetetty] = useState(!!searchParams.get("lahetetty"));
  const [lahetaLinkkiSalasananPaivittamiseksi] = useMutation(
    LAHETA_LINKKI_SALASANAN_PAIVITTAMISEKSI_MUTATION
  );

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await lahetaLinkkiSalasananPaivittamiseksi({
          variables: values,
        });
        asetaLahetetty(true);
      } catch (error) {
        console.error(error);
      }
    },
  });

  return email && token ? (
    <SalasananPaivitys email={email} token={token} />
  ) : (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Päivitä salasana
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
            sx={{ mt: 1 }}
          >
            {lahetetty && (
              <Alert severity="success" sx={{ mb: 1 }}>
                Lähetimme sähköpostiisi ohjeet salasanan päivitystä varten
              </Alert>
            )}
            <p>
              Anna sähköpostiosoitteesi, niin lähetämme sinulle ohjeet salasanan
              päivitystä varten.
            </p>
            <TextField
              type="email"
              name="email"
              label="Sähköposti"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="email"
              fullWidth
              margin="normal"
              required
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Lähetä
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
