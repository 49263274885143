import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Anna kelvollinen sähköpostiosoite")
    .required("Tämä on pakollinen kenttä"),
  name: Yup.string().required("Tämä on pakollinen kenttä"),
  puhelinnumero: Yup.string().required("Tämä on pakollinen kenttä"),
  katuosoite: Yup.string().required("Tämä on pakollinen kenttä"),
  postinumero: Yup.number().required("Tämä on pakollinen kenttä"),
  postitoimipaikka: Yup.string().required("Tämä on pakollinen kenttä"),
  tilinumero: Yup.string().required("Tämä on pakollinen kenttä"),
  tiimi: Yup.string().required("Tämä on pakollinen kenttä"),
  alaseura: Yup.string().required("Tämä on pakollinen kenttä"),
});

export const validationSchemaPassword = validationSchema.shape({
  password: Yup.string()
    .min(8, "Salasanan on oltava vähintään kahdeksan merkkiä pitkä")
    .required("Tämä on pakollinen kenttä"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Salasanojen on täsmättävä")
    .required("Tämä on pakollinen kenttä"),
});
