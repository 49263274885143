import { createContext, useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { KIRJAUTUNUT_KAYTTAJA_QUERY } from "../graphql/queries";
import { UserContextType, User } from "../types";

const AuthContext = createContext<UserContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data } = useQuery(KIRJAUTUNUT_KAYTTAJA_QUERY);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    data?.authenticatedItem && setUser(data.authenticatedItem);
  }, [data]);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    return {
      user: null,
      setUser: () => {},
    };
  }

  return context;
};
