import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./contexts/AuthContext";
import Sivu from "./components/Sivu";
import Koti from "./components/Koti";
import Ilmoittautumiset from "./components/Ilmoittautumiset";
import Koneet from "./components/Koneet";
import Kayttajatili from "./components/Kayttajatili";
import Sisaankirjautuminen from "./components/Sisaankirjautuminen";
import Rekisteroityminen from "./components/Rekisteroityminen";
import UnohtunutSalasana from "./components/UnohtunutSalasana";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Sivu>
          <Routes>
            <Route path="/" element={<Koti />} />
            <Route path="/ilmoittautumiset" element={<Ilmoittautumiset />} />
            <Route path="/koneet" element={<Koneet />} />
            <Route path="/kayttajatili" element={<Kayttajatili />} />
            <Route path="/kirjaudu" element={<Sisaankirjautuminen />} />
            <Route path="/rekisteroidy" element={<Rekisteroityminen />} />
            <Route path="/paivita-salasana" element={<UnohtunutSalasana />} />
          </Routes>
        </Sivu>
      </Router>
    </AuthProvider>
  );
}

export default App;
